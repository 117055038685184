import { getFromStorage } from "../utils/http";
import { secureStorage } from "../utils/secureStorage";
import http from "../utils/http";

export class AdminService {
  constructor() {}

  static async getCurrentCashRegister() {
    return (await http.get("api/admin/cash-registers/current")).data;
  }

  static async getEntityUtilities(params) {
    return (await http.get("api/admin/reports/entity-utilities", { params }))
      .data;
  }

  static async getReportEmployeeLiquidations(params) {
    return (
      await http.get("api/general/reports/employee-liquidations", { params })
    ).data;
  }

  static async getReportLiquidations(params) {
    return (await http.get("api/admin/reports-liquidations", { params })).data;
  }

  static async getLiquidationOverloads(params) {
    return (await http.get("api/admin/liquidation-overloads", { params })).data;
  }

  static async getLiquidationOverload(id) {
    return (await http.get(`api/admin/liquidation-overloads/${id}`)).data;
  }
  static async saveLiquidationOverload(reg) {
    if (reg.id) {
      return (await http.put(`api/admin/liquidation-overloads/${reg.id}`, reg))
        .data;
    } else {
      return (await http.post("api/admin/liquidation-overloads", reg)).data;
    }
  }

  static async deleteLiquidationOverload(id) {
    return (await http.delete(`api/admin/liquidation-overloads/${id}`)).data;
  }

  static async getLiquidations (params) {
    return (await http.get("api/admin/liquidations", { params })).data;
  }

  static async getLiquidation(id) {
    return (await http.get(`api/admin/liquidations/${id}`)).data;
  }

  static async saveLiquidations(reg) {
    if (reg.id) {
      return (await http.put(`api/admin/liquidations/${reg.id}`, reg)).data;
    } else {
      return (await http.post("api/admin/liquidations", reg)).data;
    }
  }

  static async deleteLiquidations(id) {
    return (await http.delete(`api/admin/liquidations/${id}`)).data;
  }

  static async getEmployeeSpecialties(employee_id) {
    return (
      await http.get("api/general/employee-specialties", {
        params: { employee_id }
      })
    ).data;
  }

  static async saveSourceBudget(reg) {
    return (await http.post("api/admin/source-budgets", reg)).data;
  }

  static async deleteEmployeeSpecialtie(id) {
    return (await http.delete("api/general/employee-specialties/" + id)).data;
  }

  static async saveEmployeeSpecialty(reg) {
    return (await http.post("api/general/employee-specialties", reg)).data;
  }

  static async getIdMyCashRegister() {
    return (await http.get("api/admin/id-my-cashregister")).data;
  }

  static async getCashRegisters(params) {
    return (await http.get("api/admin/cash-registers", { params })).data;
  }

  static async saveCashRegisterInput(reg) {
    if (reg.id) {
      return (await http.put(`api/admin/cash-register/inputs/${reg.id}`, reg))
        .data;
    } else {
      return (await http.post("api/admin/cash-register/inputs", reg)).data;
    }
  }

  static async getReportFinantial(params) {
    return (await http.get(`api/admin/reports/finantial`, { params })).data;
  }

  static async getDayresults(params) {
    return (await http.get(`api/admin/reports/dayresults`, { params })).data;
  }

  static async getSources(params) {
    return (await http.get(`api/admin/sources`, { params })).data;
  }

  static async deleteSource(id) {
    return (await http.delete(`api/admin/sources/${id}`)).data;
  }

  static async saveReasonBudget(reg) {
    if (reg.id) {
      return (await http.put(`api/admin/reason-budgets/${reg.id}`, reg)).data;
    } else {
      return (await http.post(`api/admin/reason-budgets`, reg)).data;
    }
  }

  static async saveReason(reg) {
    if (!reg.id) {
      return (await http.post("api/admin/reasons", reg)).data;
    } else {
      return (await http.put("api/admin/reasons/" + reg.id, reg)).data;
    }
  }

  static async deleteReason(id) {
    return (await http.delete(`api/admin/reasons/${id}`)).data;
  }

  static async saveCashRegisterOutput(reg) {
    if (reg.id) {
      return (await http.put(`api/admin/cash-register/outputs/${reg.id}`, reg))
        .data;
    } else {
      return (await http.post(`api/admin/cash-register/outputs`, reg)).data;
    }
  }

  static async saveScheduleTime(reg) {
    return (await http.post("api/general/schedule-times", reg)).data;
  }

  static async deleteSourceGroup(id) {
    return (await http.delete(`api/admin/source-groups/${id}`)).data;
  }

  static async saveSourceGroup(reg) {
    if (!reg.id) {
      return (await http.post("api/admin/source-groups", reg)).data;
    }
  }

  static async getSourceGroups(params) {
    return (await http.get("api/admin/source-groups", { params })).data;
  }

  static async getEntity(id) {
    return (await http.get("api/entitys/" + id)).data;
  }

  static async deleteEmployeeSchedule(id) {
    return (await http.delete("api/general/employee-schedules/" + id)).data;
  }

  static async getEmployeePayments(params) {
    return (await http.get("api/general/employee-payments", { params })).data;
  }

  static async getEmployeeSchedules(params) {
    return (
      await http.get("api/general/employee-schedules", {
        params
      })
    ).data;
  }

  static async saveEmployeeSchedule(reg) {
    if (!reg.id) {
      return (await http.post("api/general/employee-schedules", reg)).data;
    }
    return (await http.put("api/general/employee-schedules/" + reg.id, reg))
      .data;
  }

  static async deleteEmployee(id) {
    return await http.delete("api/general/employees/" + id);
  }

  static async getEmployee(id) {
    return await getFromStorage("api/general/employees/" + id);
  }

  static async saveEmployee(reg) {
    if (!reg.id) {
      return (await http.post("api/general/employees", reg)).data;
    } else {
      await http.put(`api/general/employees/${reg.id}`, reg);
      return reg.id;
    }
  }

  static async deleteInput(id) {
    await http.delete("api/admin/cash-register/inputs/" + id);
    return;
  }

  static async deleteCROutput(cr_id, id) {
    await http.delete("api/admin/cash-register/outputs/" + id, {
      params: { cr_id }
    });
  }

  static async getChangeRate() {
    return (await http.get("api/admin/exchange-rates/current")).data;
  }

  static async getEntitys(params, getFromServer = false) {
    return await getFromStorage("api/entitys", { params }, getFromServer);
  }

  static async getReasons(params) {
    return (await http.get("api/admin/reasons", { params })).data;
  }

  static async getCashRegister(id) {
    return (await http.get("api/admin/cash-registers/" + id)).data;
  }

  static async getSource(source_id) {
    return (await http.get("api/admin/sources/" + source_id)).data;
  }

  static async getReason(reason_id) {
    return (await http.get("api/admin/reasons/" + reason_id)).data;
  }

  static async saveCashRegister(reg) {
    if (reg.id) {
      return (await http.put("api/admin/cash-registers/" + reg.id, reg)).data;
    } else {
      return (await http.post("api/admin/cash-registers", reg)).data;
    }
  }

  static async getUsers(search) {
    let res = await http.get("api/general/users", {
      params: { search }
    });
    return res.data;
  }

  static async getUser(user_id) {
    return (await http.get("api/general/users/" + user_id)).data;
  }

  static saveEntity(reg) {
    return new Promise((rsv) => {
      if (reg.id) {
        http.put("api/entitys/" + reg.id, reg).then(() => rsv());
      } else {
        http.post("api/entitys", reg).then(() => rsv());
      }
    });
  }

  static async getExChangeRate() {
    return (await http.get("api/admin/exchange-rates")).data;
  }

  static async saveExChangeRate(reg) {
    await http.post("api/admin/exchange-rates", reg);
    return;
  }

  static async saveSource(reg) {
    if (!reg.id) {
      await http.post("api/admin/sources", reg);
      return;
    } else {
      await http.put("api/admin/sources/" + reg.id, reg);
      return;
    }
  }

  static async getEmployees(params) {
    return (
      await http.get("api/general/employees", {
        params
      })
    ).data;
  }

  static async reverseLiquidation(liquidationId) {
    return (
      await http.put(`api/admin/liquidations/${liquidationId}`, {
        reverse: 1
      })
    ).data;
  }

  static async getAreas(params) {
    return await getFromStorage("api/areas", { params });
  }

  static async getArea(id) {
    return (await http.get(`api/areas/${id}`)).data;
  }

  static async saveArea(reg) {
    secureStorage.removeItem("api/areas");
    if (reg.id === undefined) {
      return (await http.post("api/areas", reg)).data;
    } else {
      return (await http.put(`api/areas/${reg.id}`, reg)).data;
    }
  }

  static async deleteArea(id) {
    secureStorage.removeItem("api/areas");
    return (await http.delete(`api/areas/${id}`)).data;
  }
}
